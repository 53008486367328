import Model from './Model.js';

class GPSModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id',             Number);
        this.prop('gps_history_id', String);
        this.prop('latitude',       String);
        this.prop('location',       String);
        this.prop('longitude',      String);
        this.prop('motion_status',  String);
        this.prop('provider',       String);
        this.prop('trailer_code',   String);
        this.prop('updated_on',     Date);
        this.populate(values, config);

    }
    static name = 'GPSModel';
    static source = 'GPSTest';
}
export default GPSModel;